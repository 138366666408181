import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) {
  var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) {
      t && (r = t);
      var _n = 0,
        F = function F() {};
      return {
        s: F,
        n: function n() {
          return _n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[_n++]
          };
        },
        e: function e(r) {
          throw r;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    a = !0,
    u = !1;
  return {
    s: function s() {
      t = t.call(r);
    },
    n: function n() {
      var r = t.next();
      return a = r.done, r;
    },
    e: function e(r) {
      u = !0, o = r;
    },
    f: function f() {
      try {
        a || null == t["return"] || t["return"]();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useBaseUtils from "~/functions/useBaseUtils";
import { getRowsAndTickersSelected } from "~/functions/common";
import algoliaSearch from "~/mixins/algoliaSearch";
import multiChartUtils from "~/mixins/multiChartUtils";
import { createCurrencyFormatter } from "~/utils/valuationTransform/peModel";
var devStage = "dev";
var stage = process.env.LAMBDA_STAGE;
export default defineComponent({
  mixins: [algoliaSearch(), multiChartUtils],
  props: {
    selectedrows: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    rowsforchart: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columndecimals: {
      type: Number,
      "default": 2
    },
    charttype: {
      type: String,
      "default": "Multiples"
    },
    // period: { type: Number, default: 12 },
    charttitle: {
      type: String,
      "default": ""
    },
    chartsymbol: {
      type: String,
      "default": ""
    },
    loading: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn,
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle;
    var _useCiqTableOptions = useCiqTableOptions(),
      reverseDates = _useCiqTableOptions.reverseDates;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var chartDescription = computed(function () {
      var _getRowsAndTickersSel = getRowsAndTickersSelected({
          $store: $store
        }),
        rows = _getRowsAndTickersSel.rows,
        tickers = _getRowsAndTickersSel.tickers; // en-GB has no oxford comma at the end
      var list = new Intl.ListFormat("en-GB");
      return "".concat(list.format(tickers), "'s ").concat(list.format(rows));
    });
    return {
      chartDescription: chartDescription,
      reverseDates: reverseDates,
      I18nFn: I18nFn,
      sectionWrapperStyle: sectionWrapperStyle
    };
  },
  data: function data() {
    return {
      fullscreen: false,
      sheet: false,
      chartHeight: "600",
      test: null,
      hideSelf: false
    };
  },
  computed: {
    totalSeries: function totalSeries() {
      // Count total number of series (metrics * (1 + additional tickers))
      var additionalTids = Object.keys(this.addTickersForChart).length;
      var metricsCount = Object.keys(this.selectedrows).length;
      var totalSeries = metricsCount * (1 + additionalTids);
      return totalSeries;
    },
    buttonActive: function buttonActive() {
      // check that the user has selected a series to plot
      // if the user has selected a series to plot, return true
      // if the user hasn't selected any series to plot, return false
      return Object.keys(this.selectedrows).length > 0;
    },
    addDailySeries: function addDailySeries() {
      return this.$store.state.ciq["add".concat(this.charttype)] || {};
    },
    addTickersForChart: function addTickersForChart() {
      return this.$store.state.ciq.multiplesChartTickers || {};
    },
    toUSD: {
      get: function get() {
        return this.$store.state.ciq.compareMultiplesToUSD;
      },
      set: function set(value) {
        this.$store.commit("ciq/setToggle", {
          type: "compareMultiplesToUSD",
          value: value
        });
      }
    },
    compareSeries: function compareSeries() {
      // if this is "percent" all series become calculated percent
      // changes over time. need to change which axis
      // and the formatters though... hmm
      // you've got this chart torn apart there is defintiely a way to do this
      // although.. so many other settings already set. hm
      return "value";
    },
    // addTickersForAutoComplete: {
    //   get() {
    //     return Object.keys(this.addTickersForChart).reduce((acc, tid) => {
    //       acc.push(this.addTickersForChart[tid])
    //       return acc
    //     }, [])
    //   },
    //   set() {
    //     // console.log("addTickersForAutoComplete setter: ", e)
    //     return Object.keys(this.addTickersForChart).reduce((acc, tid) => {
    //       acc.push(this.addTickersForChart[tid])
    //       return acc
    //     }, [])
    //   },
    // },
    // fetchingAddTickers() {
    //   return this.$store.state.ciq[`fetchingAdd${this.charttype}`]
    //     ? "primaryAction"
    //     : false
    // },
    // items() {
    //   const results = this.entities
    //   const chosenTickers = this.addTickersForAutoComplete
    //   const allResults = results.concat(chosenTickers)
    //   return allResults
    // },
    showChart: {
      get: function get() {
        var chartType = "show".concat(this.charttype, "Chart");
        return this.$store.state.ciq[chartType];
      },
      set: function set() {
        var chartType = "show".concat(this.charttype, "Chart");
        this.$store.commit("ciq/setChartBool", {
          chartType: chartType
        });
      }
    },
    showChartLabels: {
      get: function get() {
        var chartType = "show".concat(this.charttype, "ChartLabels");
        return this.$store.state.ciq[chartType];
      },
      set: function set() {
        var chartType = "show".concat(this.charttype, "ChartLabels");
        this.$store.commit("ciq/setChartBool", {
          chartType: chartType
        });
      }
    },
    chartOptions: function chartOptions() {
      var vue = this;
      var darkMode = this.darkMode;
      var decimals = this.columndecimals;
      var chartTitle = this.charttitle;
      var chartSymbol = this.chartsymbol;
      var chartHeight = this.chartHeight;
      var fullscreen = this.fullscreen;
      var toUSD = this.toUSD;
      var I18n = this.$Amplify.I18n; // used to determine if the 2nd yAxis should draw gridlines
      // and be on the left hand side (default with a turns is right hand side)
      var onlyVal = this.rowsforchart.filter(function (f) {
        return f.yAxis === 1;
      }).length === this.rowsforchart.length; // used to determine if the 3rd yAxis should draw gridlines, only when
      // there are no turns on the chart
      var onlyPercent = this.rowsforchart.filter(function (f) {
        return f.yAxis !== 0;
      }).length === this.rowsforchart.length;
      var onlyPrice = this.rowsforchart.filter(function (f) {
        return f.yAxis === 3;
      }).length === this.rowsforchart.length;
      var onlyPriceClose = this.rowsforchart.filter(function (f) {
        return f.yAxis === "priceclose";
      }).length === this.rowsforchart.length;
      var minButtons = [{
        type: "month",
        count: 6,
        text: I18n.get("6m")
      }, {
        type: "ytd",
        text: I18n.get("ytd")
      }, {
        type: "year",
        count: 1,
        text: I18n.get("1yr")
      }, {
        type: "year",
        count: 5,
        text: I18n.get("5y")
      }, {
        type: "all",
        text: I18n.get("all")
      }];
      var allButtons = [{
        type: "month",
        count: 3,
        text: I18n.get("3m")
      }, {
        type: "month",
        count: 6,
        text: I18n.get("6m") // events: {
        //   click: function(e) {
        //     console.log("month clicked with event: ", e)
        //   }
        // }
      }, {
        type: "ytd",
        text: I18n.get("ytd")
      }, {
        type: "year",
        count: 1,
        text: I18n.get("1yr")
      }, {
        type: "year",
        count: 3,
        text: I18n.get("3yr")
      }, {
        type: "year",
        count: 5,
        text: I18n.get("5y")
      }, {
        type: "year",
        count: 10,
        text: I18n.get("10y")
      }, {
        type: "all",
        text: I18n.get("all")
      }];
      var defaultDailyTikrOptions = {
        chart: {
          // height: vue.$vuetify.breakpoint.name !== "xs" ? "56%" : null,
          height: fullscreen ? null : vue.$vuetify.breakpoint.name !== "xs" ? chartHeight : null,
          zooming: {
            mouseWheel: false
          },
          events: {
            render: function render() {
              try {
                // could also filter for f.type === line
                // or f.type !== areaspline which is what the navigator series are
                var data = this.series.filter(function (f) {
                  return !f.name.includes("Navigator");
                }).map(function (d) {
                  // const dates = d.processedXData
                  var data = d.processedYData.filter(function (f) {
                    return typeof f === "number";
                  });
                  var last = data[data.length - 1];
                  var sum = data.reduce(function (s, i) {
                    return s + i;
                  }, 0);
                  var avg = sum / data.length;
                  var color = d.color;
                  var name = d.name;
                  var high = d.dataMax;
                  var low = d.dataMin;
                  var yAxis = d.userOptions.yAxis;
                  var suffix = yAxis === 0 ? "x" : "";
                  var formatter = yAxis === 2 ? vue.formatPercent.format : vue.formatNumber.format;
                  var str = "<span style=\"color:".concat(color, "\">\u25CF </span><b>").concat(name, ":</b> ").concat(I18n.get("Mean"), ": ").concat(formatter(avg)).concat(suffix, " ").concat(I18n.get("High"), ": ").concat(formatter(high)).concat(suffix, " ").concat(I18n.get("Low"), ": ").concat(formatter(low)).concat(suffix, " ").concat(I18n.get("Last"), ": ").concat(formatter(last)).concat(suffix);
                  return str;
                });
                if (stage === devStage) {// console.log("dailyMultiple render eventCalled: ", data)
                }
                this.setTitle(null, {
                  text: data.join("<br/>"),
                  style: {
                    color: darkMode ? "#e6e6e6" : "#333333"
                  }
                });
              } catch (e) {
                console.error("error calculating summary statistics: ", e);
              }
            }
          }
        },
        colors: ["#7cb5ec", darkMode ? "#e6e6e6" : "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
        rangeSelector: {
          buttonTheme: {
            fill: this.darkMode ? "#a5a5a5" : "#E1E4EA",
            r: 5,
            style: {
              color: "black"
            },
            states: {
              hover: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              },
              select: {
                fill: this.$vuetify.theme.themes.light.primaryAction,
                style: {
                  color: "black"
                }
              }
            }
          },
          selected: vue.$vuetify.breakpoint.name !== "xs" ? 3 : 2,
          buttons: vue.$vuetify.breakpoint.name !== "xs" ? allButtons : minButtons,
          inputEnabled: false
        },
        plotOptions: {
          series: {
            showInNavigator: true,
            dataGrouping: {
              enabled: false
            }
          }
        },
        title: {
          text: chartTitle,
          style: {
            color: darkMode ? "#e6e6e6" : "#333333",
            fontSize: "16px"
          }
        },
        legend: {
          enabled: true,
          itemHiddenStyle: {
            color: darkMode ? "#2F3033" : "#cccccc"
          },
          itemStyle: {
            color: darkMode ? "#e6e6e6" : "#333333",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis"
          } // labelFormatter: function() {
          //   try {
          //     const d = this
          //     const data = d.processedYData.filter(f => typeof f === "number")
          //     const last = data[data.length - 1]
          //     const sum = data.reduce((s, i) => s + i, 0)
          //     const avg = sum / data.length
          //     // const color = d.color
          //     const name = d.name
          //     const high = d.dataMax
          //     const low = d.dataMin
          //     const yAxis = d.userOptions.yAxis
          //     const suffix = yAxis === 0 ? "x" : ""
          //     const formatter =
          //       yAxis === 2 ? vue.formatPercent.format : vue.formatNumber.format
          //     const str = `<b>${name}</b> Average: ${formatter(
          //       avg
          //     )}${suffix} High: ${formatter(high)}${suffix} Low: ${formatter(
          //       low
          //     )}${suffix} Last: ${formatter(last)}${suffix}`
          //     return str
          //   } catch (error) {
          //     console.error("error labelFormatter summary stats: ", error)
          //   }
          // }
        },
        tooltip: {
          valueDecimals: decimals,
          dateTimeLabelFormats: {
            day: "%b %e %Y"
          },
          pointFormatter: function pointFormatter() {
            try {
              var y = this.y;
              var name = this.series.name;
              var color = this.series.color;
              var yAxis = this.series.userOptions.yAxis;
              var suffix = yAxis === 0 ? "x" : "";
              var formatter = yAxis === 2 ? vue.formatPercent.format : vue.formatNumber.format;
              if (yAxis === "priceclose" || yAxis === 3) {
                var _this$series$userOpti;
                var isocode = (_this$series$userOpti = this.series.userOptions) === null || _this$series$userOpti === void 0 || (_this$series$userOpti = _this$series$userOpti.iso) === null || _this$series$userOpti === void 0 ? void 0 : _this$series$userOpti[0];
                formatter = vue.formatCurrency(isocode).format;
              }
              return "<span style=\"color:".concat(color, "\">\u25CF</span> ").concat(name, ": <b>").concat(formatter(y)).concat(suffix, "</b><br/>");
            } catch (error) {
              console.error("pointFormatter error: ", error);
              var _y = this.y;
              var _name = this.series.name;
              var _color = this.series.color;
              return "<span style=\"color:".concat(_color, "\">\u25CF</span> ").concat(_name, ": <b>").concat(_y, "</b><br/>");
            }
          },
          crosshairs: [true, true, true]
        },
        yAxis: [{
          opposite: false,
          // title: {
          //   text: `${columnUnits} ${columnIsocode}`
          // }
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          labels: {
            formatter: function formatter() {
              return "".concat(this.value, "x");
            },
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          }
        }, {
          opposite: !onlyVal,
          labels: {
            // formatter: function() {
            //   return `${this.value}${rightylabel}`
            // },
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: onlyVal ? 1 : 0
        }, {
          opposite: false,
          // title: {
          //   text: `${columnUnits} ${columnIsocode}`
          // }
          gridLineColor: this.darkMode ? "#969696" : "#e6e6e6",
          alignTicks: false,
          labels: {
            formatter: function formatter() {
              return "".concat(vue.formatPercentZeroDecimals.format(this.value));
            },
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          gridLineWidth: onlyPercent ? 1 : 0
        }, {
          opposite: !onlyPrice,
          labels: {
            // formatter: function() {
            //   return `${this.value}${rightylabel}`
            // },
            style: {
              color: darkMode ? "#e6e6e6" : "#666666"
            }
          },
          alignTicks: false,
          gridLineWidth: onlyPrice ? 1 : 0
        }, {
          id: "priceclose",
          opposite: true,
          alignTicks: false,
          gridLineWidth: onlyPriceClose ? 1 : 0
        }],
        xAxis: [{
          reversed: this.reverseDates,
          labels: {
            style: {
              color: this.darkMode ? "#e6e6e6" : "#666666"
            }
          }
        }],
        stockTools: {
          gui: {
            enabled: false
          }
        },
        credits: {
          enabled: true,
          href: "https://tikr.com",
          text: "TIKR.com",
          style: {
            cursor: "pointer",
            color: darkMode ? "#FFFFFF" : "#2F3033",
            fontSize: "15px"
          }
        },
        navigator: {
          enabled: true,
          maskFill: "rgba(255, 153, 0, 0.4)",
          handles: {
            backgroundColor: this.chartColor,
            borderColor: "#232F3E",
            symbols: ["customcircle", "customcircle"]
          }
        },
        scrollbar: {
          enabled: true,
          barBackgroundColor: "#E1E4EA",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "#E1E4EA",
          buttonBorderWidth: 0,
          buttonArrowColor: "#232F3E",
          buttonBorderRadius: 7,
          rifleColor: "#232F3E",
          trackBackgroundColor: this.darkMode ? "#a5a5a5" : "white",
          trackBorderWidth: 1,
          trackBorderColor: "#828282",
          trackBorderRadius: 7
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG" // "separator",
              // "downloadCSV"
              ],
              symbolFill: this.darkMode ? "#a5a5a5" : "#666666",
              symbolStroke: this.darkMode ? "#a5a5a5" : "#666666",
              theme: {
                fill: this.darkMode ? "#1E1E1E" : "#ffffff"
              }
            }
          },
          sourceWidth: 1000,
          sourceHeight: 600,
          scale: 1
        },
        accessibility: {
          description: this.chartDescription
        },
        scale: 1
      };
      if (darkMode) {
        defaultDailyTikrOptions.chart.backgroundColor = "#1E1E1E";
      } // TODO: construct multi-chart series here
      var series = [];
      var additionalTickersForChart = this.addTickersForChart;
      var additionalTids = Object.keys(additionalTickersForChart); // FIXME: Change this to a collection of companies / tid's the user has specifically
      // added for this company... just trying to get it working right?
      if (additionalTids.length > 0) {
        var _iterator = _createForOfIteratorHelper(this.rowsforchart),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var selected = _step.value; // using object.assign as to not manipulate store when converting currency
            series.push(Object.assign({}, selected, {
              name: "".concat(chartSymbol, " ").concat(this.I18nFn(selected.name)),
              // compare: compare,
              visible: !this.hideSelf
            }));
            var _iterator2 = _createForOfIteratorHelper(additionalTids),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var _this$addDailySeries$;
                var tid = _step2.value;
                var rowKey = selected.id;
                if ((_this$addDailySeries$ = this.addDailySeries[tid]) !== null && _this$addDailySeries$ !== void 0 && _this$addDailySeries$[rowKey]) {
                  var newObj = this.addDailySeries[tid][rowKey];
                  var newSeries = Object.assign({}, newObj, {
                    id: "".concat(tid, "-").concat(newObj.id),
                    name: "".concat(additionalTickersForChart[tid].tickersymbol, " ").concat(this.I18nFn(newObj.name)) // compare: compare,
                  });
                  series.push(newSeries);
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        var _iterator3 = _createForOfIteratorHelper(this.rowsforchart),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _selected = _step3.value;
            series.push(Object.assign({}, _selected, {
              // compare: compare,
              name: this.I18nFn(_selected.name)
            }));
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }
      if (toUSD) {
        // convert each value series to usd
        series = series.map(function (m) {
          // if it is already USD don't touch it
          if (m.iso) {
            if (m.curr.length !== m.data.length) {
              console.error("multiple value data series length not equal to currency length");
            }
            if (m.iso.length === 1 && m.iso[0] === "USD") {// already in USD, ignore series
            } else {
              // convert the series
              m.data = m.data.map(function (d, idx) {
                var pc = m.curr[idx] && m.curr[idx].length === 2 ? m.curr[idx][0] : 1;
                var usdValue = d[1] / pc;
                return [d[0], usdValue];
              });
            }
          }
          return m;
        });
      }
      return Object.assign({}, defaultDailyTikrOptions, {
        series: series
      });
    },
    darkMode: function darkMode() {
      return this.$vuetify.theme.dark;
    },
    chartColor: function chartColor() {
      return this.$store.state.chartColor;
    },
    formatDate: function formatDate() {
      var lang = navigator.language || "default";
      return new Intl.DateTimeFormat(lang, {
        timeZone: "UTC",
        year: "2-digit",
        month: "numeric",
        day: "numeric"
      });
    },
    formatNumber: function formatNumber() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    },
    formatPercent: function formatPercent() {
      var lang = navigator.language || "default";
      var decimals = this.columndecimals;
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    },
    formatPercentZeroDecimals: function formatPercentZeroDecimals() {
      var lang = navigator.language || "default";
      var decimals = 0;
      return new Intl.NumberFormat(lang, {
        style: "percent",
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });
    }
  },
  created: function created() {
    var _this = this;
    document.addEventListener("fullscreenchange", function () {
      _this.fullscreen = Boolean(document.fullscreenElement);
    });
  },
  methods: {
    formatCurrency: function formatCurrency(isoStr) {
      var lang = navigator.language || "default";
      return createCurrencyFormatter({
        minDigits: 2,
        maxDigits: 2,
        isocode: isoStr,
        lang: lang
      });
    },
    addTicker: function addTicker(e) {
      // FIXME: check if the ticker already exists on the state? ... handle this in
      // the multiples two step dispatch action
      this.$store.dispatch("ciq/valuationOneStep", e[e.length - 1]); // this.sheet = false
      this.$refs.multiplesAutocomplete.blur();
    },
    removeTicker: function removeTicker(e) {
      this.$store.commit("ciq/removeTickerFromDailyChart", {
        chartType: "multiplesChart",
        tid: e.tradingitemid.toString()
      });
      this.$refs.multiplesAutocomplete.blur();
    },
    // chooseTicker() {
    //   this.sheet = true
    // },
    clearChart: function clearChart() {
      var chartType = "".concat(this.charttype.toLowerCase(), "Chart");
      var period = "ntm"; // console.log(`Clear chartType ${chartType} for period ${period}`)
      confirm("Are you sure you want to clear the chart?") && this.$store.commit("ciq/clearChart", {
        chartType: chartType,
        period: period
      });
    } // customFilter(f) {
    //   // remove the already selected items from the autocomplete
    //   // select while allowing them to be present
    //   // for multiple chips
    //   let alreadyFetched = this.addTickersForChart
    //   let activeTicker = this.$store.state.ciq.ticker || {}
    //   if (
    //     activeTicker.tradingitemid &&
    //     f.tradingitemid === activeTicker.tradingitemid
    //   ) {
    //     return false
    //   }
    //   return alreadyFetched[f.tradingitemid] ? false : true
    // },
  }
});